<template>
  <transition name="fade">
    <div class="gallery-modal" v-if="visible">
      <div class="layer" @click.prevent="close"></div>

      <div class="gallery-modal__content">
        <button class="gallery-modal__close" @click.prevent="close">
          <SvgSprite class="icon" symbol="ui-close" size="24" />
        </button>

        <div class="gallery-modal__picture">
          <div class="gallery-modal__picture-inner">
            <img :src="data.picture.src" :alt="data.title" />
          </div>
        </div>

        <div class="gallery-modal__content-inner" v-if="data.author">
          <div class="gallery-modal__user h4" v-if="data.author.firstname">
            {{ data.author.firstname }}
          </div>
          <p class="gallery-modal__status h5" v-if="data.author.role">
            {{ data.author.role }}
          </p>
          <div class="gallery-modal__tags">
            <Tag
              v-if="data.author.totem"
              class="gallery-modal__tags__tag"
              :data="{ icon: 'totem', label: data.author.totem }"
            />

            <Tag
              v-if="data.author.quali"
              class="gallery-modal__tags__tag"
              :data="{ icon: 'quali', label: data.author.quali }"
            />

            <Tag
              class="gallery-modal__tags__tag"
              v-if="data.author.branch"
              :data="{
                icon: data.author.branch
                  .toLowerCase()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, ''),
                label: data.author.branch,
              }"
            />

            <Tag
              v-if="data.author.unit"
              class="gallery-modal__tags__tag"
              :data="{ icon: 'unit', label: data.author.unit }"
            />
          </div>
          <div class="gallery-modal__legend" v-if="data.text">
            {{ data.text }}
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import Tag from '@/components/Tag.vue'

export default defineComponent({
  name: 'GalleryModal',
  props: {
    data: Object,
    visible: {
      type: Boolean,
      default: false,
    },
  } as any, // eslint-disable-line
  components: {
    Tag,
  },
  setup(props, ctx) {
    const close = () => {
      document.body.classList.remove('no-scroll')
      ctx.emit('update:visible', false)
    }

    return {
      close,
    }
  },
})
</script>

<style lang="scss" scoped>
.gallery-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $athens-gray;
  z-index: 5;

  @include mq(s) {
    background: transparent;
  }

  @include mq($until: m) {
    overflow: auto;
  }

  @include mq(m) {
    position: absolute;
  }
}

.layer {
  @include get-all-space;
  background: rgba($botticelli, 0.9);
  cursor: pointer;

  // @include mq($until: s) {
  //   display: none;
  // }
}

.gallery-modal__content {
  @include center-x;

  top: 5.5rem;
  width: 100%;
  background: $c-white;

  @include mq(s) {
    max-width: 55rem;
  }
}

.gallery-modal__close {
  position: absolute;
  top: 1.2rem;
  right: 0.7rem;

  display: flex;
  justify-content: center;
  align-content: center;
  background: transparent;
  border: 0;
  cursor: pointer;
}

.gallery-modal__content-inner {
  padding: 2.4rem;
}

.gallery-modal__picture {
  padding: 3.5rem 4.5rem;
  background: $athens-gray;
}

.gallery-modal__picture-inner {
  img {
    width: 100%;
  }
}

.gallery-modal__status {
  color: $hippie-blue;
  font-size: 1.4rem;
  font-weight: bold;
  text-transform: capitalize;
}
.gallery-modal__tags {
  margin-top: $spacing * 0.8;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.gallery-modal__tags__tag {
  margin-right: $spacing / 4;
  line-height: 1;
}

.gallery-modal__legend {
  margin-top: 1.5rem;
  max-width: 40rem;
  color: $regal-blue;
  font-size: 1.4rem;
  line-height: 2rem;
}

// Transition

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
