
import { defineComponent } from 'vue'

import Tag from '@/components/Tag.vue'

export default defineComponent({
  name: 'GalleryModal',
  props: {
    data: Object,
    visible: {
      type: Boolean,
      default: false,
    },
  } as any, // eslint-disable-line
  components: {
    Tag,
  },
  setup(props, ctx) {
    const close = () => {
      document.body.classList.remove('no-scroll')
      ctx.emit('update:visible', false)
    }

    return {
      close,
    }
  },
})
