<template>
  <div class="wiki" v-if="content && chromeData">
    <Hero :title="content.title" :picture="chromeData?.headers.wiki">
      <div class="switch-search" v-if="content.isQuali">
        <transition-group name="fade">
          <div v-if="searchByAdjectives">
            <SearchAdjectives
              route="WikiSearchByAdjectives"
              :chromeList="chromeData.lists.adjectives"
              :category="category"
              :list="list"
            />
            <div class="switch-search__toggle" @click="toggleSearch">
              <span>Recherche par Quali</span>
              <SvgSprite symbol="ui-arrow-right-light" size="16" />
            </div>
          </div>

          <div v-else>
            <Search
              route="WikiSearch"
              :placeholder="'Recherche par Quali (exemple: Eau de source)'"
              :category="category"
              :list="list"
            />
            <div class="switch-search__toggle" @click="toggleSearch">
              <span>Recherche par adjectifs</span>
              <SvgSprite symbol="ui-arrow-right-light" size="16" />
            </div>
          </div>
        </transition-group>
      </div>

      <Filter
        v-else-if="content.isAds"
        v-model:value="filter"
        :options="['Tous', 'Je cherche', 'J\'offre', 'Autres']"
      />

      <Search v-else route="WikiSearch" :category="category" :list="list" />
    </Hero>

    <div class="actions" v-if="category || list">
      <BackBtn />

      <router-link
        v-if="wikiType"
        :to="{ name: wikiType.slug, params: { subcat: list } }"
        class="add"
      >
        <p>{{ wikiType.copy }}</p>
        <div class="bullet">
          <SvgSprite symbol="icons-plus" size="20" />
        </div>
      </router-link>
    </div>

    <transition name="fade">
      <div class="wrapper" v-if="!loading">
        <div
          class="cards"
          :class="{
            'cards-double': content.cards ? content.cards[0].picture : '',
          }"
          v-if="list"
        >
          <router-link
            class="m-xs"
            v-for="item in content.cards"
            :to="{ path: item.url }"
            :key="item.url"
            :href="item.url"
          >
            <WikiCard :data="item" />
          </router-link>

          <p v-if="!content.cards">No content</p>
        </div>

        <div
          class="sections"
          :class="{
            'sections-double': content.subs ? content.subs[0].picture : '',
          }"
          v-else-if="category"
        >
          <router-link
            v-for="item in content.subs"
            :key="item.url"
            class="m-xs"
            :to="{
              name: 'Wiki',
              params: {
                category: item.type.category,
                list: item.type.list,
              },
            }"
          >
            <Section :data="item" />
          </router-link>

          <!-- handle case where category can have directly cards -->

          <template v-if="content.isAds">
            <template v-if="filteredList.length > 0">
              <router-link
                class="m-xs"
                v-for="item in filteredList"
                :to="{ path: item.url }"
                :key="item.url"
              >
                <Card pageType="ads" :data="item" />
              </router-link>
            </template>

            <template v-else>
              <p>No content</p>
            </template>
          </template>

          <template v-else-if="!content.isPhotos">
            <router-link
              class="m-xs"
              v-for="item in content.cards"
              :to="{ path: item.url }"
              :key="item.url"
            >
              <WikiCard :data="item" />
            </router-link>
          </template>

          <p v-if="!content.subs && !content.cards">No content</p>
        </div>

        <div class="categories" v-else>
          <router-link
            v-for="item in content.subs"
            :key="item.url"
            :to="{
              name: 'Wiki',
              params: {
                category: item.type.category,
              },
            }"
          >
            <Category :data="item" />
          </router-link>
        </div>

        <template v-if="content.isPhotos">
          <gallery :data="content.cards" />
        </template>
      </div>
    </transition>
  </div>
  <div v-else>
    <Error />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import { chrome } from '@/guides/endpoints/chrome'
import { list } from '@/guides/endpoints/wiki'
import Hero from '@/components/Hero.vue'
import BackBtn from '@/components/BackBtn.vue'
import Card from '@/components/Card.vue'
import Category from '@/components/inventory/Category.vue'
import Error from '@/components/Error.vue'
import Filter from '@/components/Filter.vue'
import Gallery from '@/components/Gallery.vue'
import Search from '@/components/inventory/Search.vue'
import SearchAdjectives from '@/components/inventory/SearchAdjectives.vue'
import Section from '@/components/inventory/Section.vue'
import WikiCard from '@/components/inventory/Card.vue'

import { push } from '@/inc/tracking'

export default defineComponent({
  props: {
    category: {
      type: String,
      default: '',
    },
    list: {
      type: String,
      default: '',
    },
  },
  components: {
    BackBtn,
    Card,
    Category,
    Error,
    Filter,
    Gallery,
    Hero,
    Search,
    SearchAdjectives,
    Section,
    WikiCard,
  },
  setup(props) {
    const chromeData = ref()
    const content = ref()
    const filteredList = ref()
    const info = ref()
    const loading = ref(false)
    const searchByAdjectives = ref(false)
    const filter = ref(0)

    chrome().then(r => {
      chromeData.value = r.data
      console.log('chrome', r.data)
    })

    const wikiType = computed(() => {
      if (content.value.isActivity)
        return { copy: 'Ajouter une fiche de jeu', slug: 'WikiAddGame' }
      if (content.value.isTip)
        return { copy: 'Ajouter un bon plan', slug: 'WikiAddTip' }
      if (content.value.isAds)
        return { copy: 'Ajouter une petite annonce', slug: 'WikiAddAds' }
      if (content.value.isQuali)
        return { copy: 'Ajouter une fiche Quali', slug: 'WikiAddQuali' }
      if (content.value.isRecipe)
        return { copy: 'Ajouter une fiche recette', slug: 'WikiAddRecipe' }
      if (content.value.isTotem)
        return { copy: 'Ajouter une fiche Totem', slug: 'WikiAddTotem' }
      if (content.value.isWoodcraft)
        return { copy: 'Ajouter une fiche Woodcraft', slug: 'WikiAddWoodcraft' }
      if (content.value.isRituals)
        return { copy: 'Ajouter un rite ou rituel', slug: 'WikiAddRitual' }
      return ''
    })

    const toggleSearch = () => {
      searchByAdjectives.value = !searchByAdjectives.value
    }

    const fetchContent = () => {
      loading.value = true

      list({
        category: props.category,
        list: props.list,
      }).then(res => {
        if (res?.data?.content) {
          content.value = res.data.content
          filteredList.value = content.value.cards
        }

        loading.value = false
      })

      push({
        name: 'category-wiki',
        category: 'category-wiki',
        action: 'click',
        label: props.category,
      })
    }

    fetchContent()

    const filteredItems = () => {
      loading.value = true

      const filters = ['Tous', 'Je cherche', "J'offre", 'Autre']

      if (filter.value === 0) {
        setTimeout(() => {
          loading.value = false
        }, 500)
        return (filteredList.value = content.value.cards)
      }

      filteredList.value = content.value.cards.filter(function(card: any) {
        setTimeout(() => {
          loading.value = false
        }, 500)
        return card.category === filters[filter.value || 0]
      })
    }

    watch(() => props.category, fetchContent)
    watch(() => props.list, fetchContent)
    watch(() => filter.value, filteredItems)

    return {
      chromeData,
      content,
      filter,
      info,
      loading,
      searchByAdjectives,
      toggleSearch,
      wikiType,
      filteredItems,
      filteredList,
    }
  },
})
</script>

<style lang="scss" scoped>
.wiki__custom-search {
}
.categories {
  width: 100%;
  display: grid;
  gap: 25px;
  grid-template-columns: repeat(2, 1fr);
}

.sections {
  > * {
    display: block;
  }
}

.sections-double {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(2, 1fr);

  > * {
    margin: 0;
  }
}

.add {
  position: fixed;
  z-index: 20;
  bottom: 30px;
  right: 30px;

  transition: opacity 0.3s;

  &:hover {
    opacity: 0.6;
  }

  .bullet {
    display: flex;
    width: 48px;
    height: 48px;
    align-items: center;
    justify-content: center;
    background: $regal-blue;
    border-radius: 50%;
  }

  p {
    display: none;
    font-size: 1.4rem;
    color: $tangerine;
    margin-right: 10px;
  }

  svg {
    fill: $c-white;
  }

  @include mq($until: s) {
    opacity: 0;
    animation: bubble 0.5s forwards;
    animation-delay: 0.3s;
  }
}

@include mq(s) {
  .actions {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    margin-bottom: 30px;
    margin-top: -40px;

    .back-btn {
      position: static;
      margin: 0;
    }

    .add {
      z-index: 1;
      display: inline-flex;
      position: static;
      align-items: center;
    }

    .add p {
      display: block;
    }

    .add .bullet {
      width: 30px;
      height: 30px;

      svg {
        width: 13px;
        height: auto;
      }
    }
  }
}

.cards > * {
  display: block;
}

.cards-double {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(2, 1fr);

  > * {
    margin: 0;
  }
}

@include mq(m) {
  .cards-double {
    gap: 40px;
    grid-template-columns: repeat(2, 1fr);
  }
}

@include mq(xl) {
  .sections {
    display: grid;
    gap: 25px;
    grid-template-columns: repeat(2, 1fr);

    > * {
      margin: 0;
    }
  }

  .cards {
    display: grid;
    gap: 25px;
    grid-template-columns: repeat(2, 1fr);

    > * {
      margin: 0;
    }
  }

  .cards-double {
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);
  }
}

.switch-search {
  position: relative;
  min-height: 7rem;

  > div {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
}

.switch-search__toggle {
  display: flex;
  align-items: center;
  margin-top: 0.8rem;
  margin-left: auto;
  color: white;
  font-size: 1.4rem;
  line-height: 2rem;
  cursor: pointer;

  svg {
    margin-left: 0.6rem;
    fill: white;
  }
}

// Keyframes

@keyframes bubble {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

// Transitions

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
