
import { defineComponent, ref } from 'vue'
// import Btn from '@/components/Btn.vue'
import GalleryModal from '@/components/GalleryModal.vue'

export default defineComponent({
  name: 'Gallery',
  components: {
    // Btn,
    GalleryModal,
  },
  props: {
    data: Object,
  } as any, // eslint-disable-line

  setup() {
    const dataModal = ref({})
    const modalVisible = ref(false)

    const loadmore = () => {
      console.log('load more photos')
    }

    const openModal = (data: any) => {
      modalVisible.value = true
      console.log('pouet')

      dataModal.value = data
    }

    return {
      dataModal,
      loadmore,
      modalVisible,
      openModal,
    }
  },
})
