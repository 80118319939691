<template>
  <div class="gallery" v-if="data">
    <div class="gallery-inner">
      <div
        class="gallery__item"
        v-for="(item, index) in data.slice(0, 11)"
        :key="`item-${index}`"
        @click.prevent="openModal(item)"
      >
        <img :src="item.picture.src" :alt="item.title" />
      </div>

      <GalleryModal :data="dataModal" v-model:visible="modalVisible" />
    </div>

    <!-- <Btn class="gallery__btn" @click.prevent="loadmore">En voir plus</Btn> -->
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
// import Btn from '@/components/Btn.vue'
import GalleryModal from '@/components/GalleryModal.vue'

export default defineComponent({
  name: 'Gallery',
  components: {
    // Btn,
    GalleryModal,
  },
  props: {
    data: Object,
  } as any, // eslint-disable-line

  setup() {
    const dataModal = ref({})
    const modalVisible = ref(false)

    const loadmore = () => {
      console.log('load more photos')
    }

    const openModal = (data: any) => {
      modalVisible.value = true
      console.log('pouet')

      dataModal.value = data
    }

    return {
      dataModal,
      loadmore,
      modalVisible,
      openModal,
    }
  },
})
</script>

<style scoped lang="scss">
.gallery {
  margin: 0 auto;
  max-width: 72rem;
}

.gallery-inner {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(20, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;

  @include mq(s) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(10, 1fr);
  }
}

.gallery__item {
  position: relative;
  overflow: hidden;
  width: 100%;
  background: $hippie-blue;
  cursor: pointer;

  img {
    @include get-all-space;

    object-fit: cover;
    transition: transform 0.3s;
  }

  &:hover {
    img {
      transform: scale(1.02);
    }
  }
}

.gallery__item:nth-child(12n + 1) {
  @include aspect-ratio(19, 27);
  grid-area: 1 / 1 / 6 / 2;
}
.gallery__item:nth-child(12n + 2) {
  grid-area: 1 / 2 / 3 / 3;
}
.gallery__item:nth-child(12n + 3) {
  grid-area: 3 / 2 / 6 / 3;
}
.gallery__item:nth-child(12n + 4) {
  grid-area: 6 / 1 / 9 / 2;
}
.gallery__item:nth-child(12n + 5) {
  grid-area: 9 / 1 / 11 / 2;
}
.gallery__item:nth-child(12n + 6) {
  grid-area: 6 / 2 / 11 / 3;
}
.gallery__item:nth-child(12n + 7) {
  grid-area: 11 / 1 / 16 / 2;
}
.gallery__item:nth-child(12n + 8) {
  grid-area: 11 / 2 / 13 / 3;
}
.gallery__item:nth-child(12n + 9) {
  grid-area: 13 / 2 / 16 / 3;
}
.gallery__item:nth-child(12n + 10) {
  grid-area: 16 / 1 / 19 / 2;
}
.gallery__item:nth-child(12n + 11) {
  grid-area: 19 / 1 / 21 / 2;
}
.gallery__item:nth-child(12n + 12) {
  grid-area: 16 / 2 / 21 / 3;
}

@include mq(s) {
  .gallery__item:nth-child(12n + 1) {
    @include aspect-ratio(19, 27);

    grid-area: 1 / 1 / 6 / 2;
  }
  .gallery__item:nth-child(12n + 2) {
    grid-area: 1 / 2 / 3 / 3;
  }
  .gallery__item:nth-child(12n + 3) {
    grid-area: 3 / 2 / 6 / 3;
  }
  .gallery__item:nth-child(12n + 4) {
    grid-area: 1 / 3 / 6 / 4;
  }
  .gallery__item:nth-child(12n + 5) {
    grid-area: 1 / 4 / 4 / 5;
  }
  .gallery__item:nth-child(12n + 6) {
    grid-area: 4 / 4 / 6 / 5;
  }
  .gallery__item:nth-child(12n + 7) {
    grid-area: 6 / 1 / 11 / 2;
  }
  .gallery__item:nth-child(12n + 8) {
    grid-area: 6 / 2 / 9 / 3;
  }
  .gallery__item:nth-child(12n + 9) {
    grid-area: 9 / 2 / 11 / 3;
  }
  .gallery__item:nth-child(12n + 10) {
    grid-area: 6 / 3 / 11 / 4;
  }
  .gallery__item:nth-child(12n + 11) {
    grid-area: 6 / 4 / 8 / 5;
  }
  .gallery__item:nth-child(12n + 12) {
    grid-area: 8 / 4 / 11 / 5;
  }
}
</style>
