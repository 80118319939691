
import { computed, defineComponent, ref, watch } from 'vue'
import { chrome } from '@/guides/endpoints/chrome'
import { list } from '@/guides/endpoints/wiki'
import Hero from '@/components/Hero.vue'
import BackBtn from '@/components/BackBtn.vue'
import Card from '@/components/Card.vue'
import Category from '@/components/inventory/Category.vue'
import Error from '@/components/Error.vue'
import Filter from '@/components/Filter.vue'
import Gallery from '@/components/Gallery.vue'
import Search from '@/components/inventory/Search.vue'
import SearchAdjectives from '@/components/inventory/SearchAdjectives.vue'
import Section from '@/components/inventory/Section.vue'
import WikiCard from '@/components/inventory/Card.vue'

import { push } from '@/inc/tracking'

export default defineComponent({
  props: {
    category: {
      type: String,
      default: '',
    },
    list: {
      type: String,
      default: '',
    },
  },
  components: {
    BackBtn,
    Card,
    Category,
    Error,
    Filter,
    Gallery,
    Hero,
    Search,
    SearchAdjectives,
    Section,
    WikiCard,
  },
  setup(props) {
    const chromeData = ref()
    const content = ref()
    const filteredList = ref()
    const info = ref()
    const loading = ref(false)
    const searchByAdjectives = ref(false)
    const filter = ref(0)

    chrome().then(r => {
      chromeData.value = r.data
      console.log('chrome', r.data)
    })

    const wikiType = computed(() => {
      if (content.value.isActivity)
        return { copy: 'Ajouter une fiche de jeu', slug: 'WikiAddGame' }
      if (content.value.isTip)
        return { copy: 'Ajouter un bon plan', slug: 'WikiAddTip' }
      if (content.value.isAds)
        return { copy: 'Ajouter une petite annonce', slug: 'WikiAddAds' }
      if (content.value.isQuali)
        return { copy: 'Ajouter une fiche Quali', slug: 'WikiAddQuali' }
      if (content.value.isRecipe)
        return { copy: 'Ajouter une fiche recette', slug: 'WikiAddRecipe' }
      if (content.value.isTotem)
        return { copy: 'Ajouter une fiche Totem', slug: 'WikiAddTotem' }
      if (content.value.isWoodcraft)
        return { copy: 'Ajouter une fiche Woodcraft', slug: 'WikiAddWoodcraft' }
      if (content.value.isRituals)
        return { copy: 'Ajouter un rite ou rituel', slug: 'WikiAddRitual' }
      return ''
    })

    const toggleSearch = () => {
      searchByAdjectives.value = !searchByAdjectives.value
    }

    const fetchContent = () => {
      loading.value = true

      list({
        category: props.category,
        list: props.list,
      }).then(res => {
        if (res?.data?.content) {
          content.value = res.data.content
          filteredList.value = content.value.cards
        }

        loading.value = false
      })

      push({
        name: 'category-wiki',
        category: 'category-wiki',
        action: 'click',
        label: props.category,
      })
    }

    fetchContent()

    const filteredItems = () => {
      loading.value = true

      const filters = ['Tous', 'Je cherche', "J'offre", 'Autre']

      if (filter.value === 0) {
        setTimeout(() => {
          loading.value = false
        }, 500)
        return (filteredList.value = content.value.cards)
      }

      filteredList.value = content.value.cards.filter(function(card: any) {
        setTimeout(() => {
          loading.value = false
        }, 500)
        return card.category === filters[filter.value || 0]
      })
    }

    watch(() => props.category, fetchContent)
    watch(() => props.list, fetchContent)
    watch(() => filter.value, filteredItems)

    return {
      chromeData,
      content,
      filter,
      info,
      loading,
      searchByAdjectives,
      toggleSearch,
      wikiType,
      filteredItems,
      filteredList,
    }
  },
})
